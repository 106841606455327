function Suzuki() {
  return (
    <div className="suzuki" id="suzuki">
      <h2>Why use the Suzuki Method?</h2>
      <ul>
        <li>
          <p>
            Children do not need to read to play the Suzuki method. Since Suzuki
            is taught by listening and developing an ear for music, being able
            to read music is not necessary for beginning students.
          </p>
        </li>
        <li>
          <p>
            Since children do not need to sight read music, this method allows
            children to begin lessons at a younger age than other teaching
            methods. Many children begin learning to play at the age of three
            years (or younger)!
          </p>
        </li>
        <li>
          <p>
            Since children learn to play music “by ear,” they learn to play
            music with feeling. Music does not sound mechanical or halting as a
            child tries to decipher sheet music.
          </p>
        </li>
        <li>
          <p>
            Children obtain a feeling of success early on in the process, as the
            Suzuki books teach the children to play real songs right away.
            Meaningless scales and finger exercises are avoided.
          </p>
        </li>
        <li>
          <p>
            The positive environment and reinforcement used with the Suzuki
            method makes playing a joyful event rather than a chore.
          </p>
        </li>
      </ul>
      <h3>
        View my{" "}
        <a
          href="https://suzukiassociation.org/people/sean-sanchez/"
          className="suzuki-link"
        >
          profile
        </a>{" "}
        with the Suzuki Association of the Americas.
      </h3>
    </div>
  );
}

export default Suzuki;
